
         @import "config.scss";
      
.container {
   position: relative;

   .mobile {
      display: block;
   }
   .desktop {
      display: none;
   }

   .blur {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;

      &--hidden {
         transition: opacity 0.25s ease;
         opacity: 0;
      }
   }
}

@include media('>=tablet') {
   .container {
      .mobile {
         display: none;
      }
      .desktop {
         display: block;
      }
   }
}
